<template>
  <div class="">
    <b-form enctype="multipart/form-data" @submit.prevent="submitUpdate">
      <div class="form-row">
        <div class="col-md-8">
          <b-card header="Informações" class="mb-1">
            <b-card-text>
              <div class="form-row">
                <div class="col-md-12">
                  <div class="form-group mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Título
                    </label>
                    <input
                      require
                      type="text"
                      :class="{ 'is-invalid': $v.item.title.$error }"
                      v-model="item.title"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>
          <b-tabs pills class="mt-2">
            <b-tab title="Tags" active>
              <b-card no-body header-class="mb-0">
                <b-card>
                  <b-card-text>
                    <div class="form-row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="">
                            <i class="text-danger bi bi-record-circle"></i>
                            Tag(s) Concurso(s)
                          </label>
                          <v-select
                            multiple
                            label="title"
                            item-text="title"
                            item-value="code"
                            v-model="item.tags.contest"
                            placeholder="Digite o título da tag"
                            :options="optionsTagConcurso"
                            @search="fetchTagsCategoryConcurso"
                            :class="{
                              'is-invalid': $v.item.tags.contest.$error,
                            }"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group mb-0">
                          <label for="">
                            <i class="text-danger bi bi-record-circle"></i>
                            Tag(s) Linha do tempo
                          </label>
                          <v-select
                            multiple
                            label="title"
                            item-text="title"
                            item-value="code"
                            v-model="item.tags.timeline"
                            placeholder="Digite o título da tag"
                            :options="optionsTagLinhaDoTempo"
                            @search="fetchTagsCategoryLinhaDoTempo"
                            :class="{
                              'is-invalid': $v.item.tags.timeline.$error,
                            }"
                          />
                        </div>
                      </div>
                    </div>
                  </b-card-text>
                </b-card>
              </b-card>
            </b-tab>
            <b-tab title="Fichas Técnicas">
              <b-card no-body>
                <b-table
                  :items="dataItemsDatasheets"
                  responsive
                  :fields="fields"
                  primary-key="id"
                  show-empty
                  empty-text="Nenhum registro até o momento."
                >
                  <template #cell(title)="data">
                    <a
                      :href="`/contests/datasheets?title=${data.item.title}`"
                      target="_blank"
                    >
                      <i class="bi bi-box-arrow-up-right"></i>
                      {{ data.item.title }}
                    </a>
                  </template>
                </b-table>
              </b-card>
            </b-tab>
          </b-tabs>
        </div>

        <div class="col-md-4">
          <b-card header="Arquivo">
            <p class="mb-3 mt-2">
              <a :href="item.url" target="_blank" class="text-primary">
                <i class="bi bi-file-earmark"></i> Clique aqui
              </a>
              para visualizar o arquivo.
            </p>
            <b-card-text>
              <input
                style="padding: 0.4rem 0.5rem"
                type="file"
                id="file"
                ref="file"
                class="form-control"
                @change="handleFileUpload()"
              />
            </b-card-text>
            <small class="d-block mb-1">Limite máximo: 10 MB</small>
            <div class="alert alert-primary p-1 mb-0">
              Para substituir o arquivo atual, selecione um novo arquivo.
            </div>
          </b-card>
          <ButtonsActionsFooter
            routerBack="notices-list"
            variant="success"
            :submited="submited"
            text="Salvar"
            subtext="Aguarde..."
          />
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCardText,
  BCard,
  VBTooltip,
  VBToggle,
  BEmbed,
  BLink,
  BAlert,
  BCardFooter,
  BImg,
  BTab,
  BTabs,
  BTable,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";

export default {
  components: {
    BCard,
    BEmbed,
    BCardText,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BLink,
    BAlert,
    ButtonsActionsFooter,
    BCardFooter,
    BImg,
    BTab,
    BTabs,
    BTable,
  },
  data() {
    return {
      preview: "",
      file: "",
      uuid: null,
      submited: false,
      filteredOptions: [],
      wasUploads: 0,
      snowOption: {
        theme: "snow",
        placeholder: "Descrição da aula",
        height: "300",
      },
      dataItemsDatasheets: [],
      fields: [
        {
          key: "title",
          label: "Título",
          sortable: true,
        },
      ],
      item: {
        title: "",
        file: "",
        media_type_id: 4,
        tags: {
          contest: [],
          timeline: [],
        },
      },
      optionsTagTema: [],
      optionsTagConcurso: [],
      optionsTagLinhaDoTempo: [],
    };
  },
  validations: {
    item: {
      title: {
        required,
      },
      tags: {
        contest: {
          required,
        },
        timeline: {
          required,
        },
      },
    },
  },
  created() {
    this.uuid = this.$route.params.uuid;
    this.getData();
  },
  computed: {
    ...mapState("Media", ["materials", "material", "media"]),
    ...mapState("Teacher", ["teachers", "searchTeachers"]),
  },
  methods: {
    async fetchTagsCategoryConcurso(term) {
      if (term.length > 2) {
        this.optionsTagConcurso = await this.$store.dispatch("Tag/searchTags", {
          category_id: 2,
          term: term,
        });
      }
    },
    async fetchTagsCategoryLinhaDoTempo(term) {
      if (term.length > 2) {
        this.optionsTagLinhaDoTempo = await this.$store.dispatch(
          "Tag/searchTags",
          { category_id: 3, term: term }
        );
      }
    },
    handleFileUpload() {
      this.item.file = this.$refs.file.files[0];
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name.replaceAll(" ", "-").toLowerCase();
      }
      return `${files.length} arquivos selecionados`;
    },
    submitUpdate() {
      this.$v.$touch();
      const formData = new FormData();
      formData.append("file", this.item.file ?? "");
      formData.append("title", this.item.title);
      formData.append("media_type_id", this.item.media_type_id);
      formData.append("tags", JSON.stringify(this.item.tags));

      if (!this.$v.$error) {
        this.submited = true;
        this.$store
          .dispatch("Media/update", { uuid: this.item.uuid, data: formData })
          .then(() => {
            this.$v.$reset;
            this.notifyDefault("success");
            this.getData();
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    async getData() {
      this.$store
        .dispatch("Media/show", this.$route.params.uuid)
        .then((res) => {
          this.item = {
            uuid: res.uuid,
            title: res.title,
            url: res.url,
            media_type_id: 4,
            teacher: res.teacher,
            tags: {
              subject: res.tags.subject,
              contest: res.tags.contest,
              timeline: res.tags.timeline,
            },
          };

          this.dataItemsDatasheets = res.data.datasheets;
        });
    },
    confirmDelete() {
      this.$swal({
        title: "Tem certeza?",
        text: "",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("Media/deleteFile", { uuid: this.item.uuid })
            .then(() => {
              this.getData();
              this.$swal({
                icon: "success",
                title: "Deletado!",
                text: "Arquivo deletado com sucesso.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
    uploadFile() {
      this.$v.$touch();

      const formData = new FormData();
      formData.append("file", this.item.file);

      if (!this.$v.$error) {
        this.submited = true;
        this.$store
          .dispatch("Media/create", formData)
          .then(() => {
            this.$v.$reset;
            this.notifyDefault("success");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
  },
};
</script>

<style>
.beforeUpload .icon {
  width: 50px !important;
}
</style>
