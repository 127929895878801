var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "b-form",
        {
          attrs: { enctype: "multipart/form-data" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitUpdate.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "col-md-8" },
              [
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Informações" } },
                  [
                    _c("b-card-text", [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "form-group mb-0" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                              _vm._v(" Título "),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.title,
                                  expression: "item.title",
                                },
                              ],
                              staticClass: "form-control",
                              class: { "is-invalid": _vm.$v.item.title.$error },
                              attrs: { require: "", type: "text" },
                              domProps: { value: _vm.item.title },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "title",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-tabs",
                  { staticClass: "mt-2", attrs: { pills: "" } },
                  [
                    _c(
                      "b-tab",
                      { attrs: { title: "Tags", active: "" } },
                      [
                        _c(
                          "b-card",
                          { attrs: { "no-body": "", "header-class": "mb-0" } },
                          [
                            _c(
                              "b-card",
                              [
                                _c("b-card-text", [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _c("i", {
                                              staticClass:
                                                "text-danger bi bi-record-circle",
                                            }),
                                            _vm._v(" Tag(s) Concurso(s) "),
                                          ]),
                                          _c("v-select", {
                                            class: {
                                              "is-invalid":
                                                _vm.$v.item.tags.contest.$error,
                                            },
                                            attrs: {
                                              multiple: "",
                                              label: "title",
                                              "item-text": "title",
                                              "item-value": "code",
                                              placeholder:
                                                "Digite o título da tag",
                                              options: _vm.optionsTagConcurso,
                                            },
                                            on: {
                                              search:
                                                _vm.fetchTagsCategoryConcurso,
                                            },
                                            model: {
                                              value: _vm.item.tags.contest,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item.tags,
                                                  "contest",
                                                  $$v
                                                )
                                              },
                                              expression: "item.tags.contest",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group mb-0" },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _c("i", {
                                              staticClass:
                                                "text-danger bi bi-record-circle",
                                            }),
                                            _vm._v(" Tag(s) Linha do tempo "),
                                          ]),
                                          _c("v-select", {
                                            class: {
                                              "is-invalid":
                                                _vm.$v.item.tags.timeline
                                                  .$error,
                                            },
                                            attrs: {
                                              multiple: "",
                                              label: "title",
                                              "item-text": "title",
                                              "item-value": "code",
                                              placeholder:
                                                "Digite o título da tag",
                                              options:
                                                _vm.optionsTagLinhaDoTempo,
                                            },
                                            on: {
                                              search:
                                                _vm.fetchTagsCategoryLinhaDoTempo,
                                            },
                                            model: {
                                              value: _vm.item.tags.timeline,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.item.tags,
                                                  "timeline",
                                                  $$v
                                                )
                                              },
                                              expression: "item.tags.timeline",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-tab",
                      { attrs: { title: "Fichas Técnicas" } },
                      [
                        _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _c("b-table", {
                              attrs: {
                                items: _vm.dataItemsDatasheets,
                                responsive: "",
                                fields: _vm.fields,
                                "primary-key": "id",
                                "show-empty": "",
                                "empty-text": "Nenhum registro até o momento.",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "cell(title)",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "/contests/datasheets?title=" +
                                              data.item.title,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "bi bi-box-arrow-up-right",
                                          }),
                                          _vm._v(
                                            " " + _vm._s(data.item.title) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "b-card",
                  { attrs: { header: "Arquivo" } },
                  [
                    _c("p", { staticClass: "mb-3 mt-2" }, [
                      _c(
                        "a",
                        {
                          staticClass: "text-primary",
                          attrs: { href: _vm.item.url, target: "_blank" },
                        },
                        [
                          _c("i", { staticClass: "bi bi-file-earmark" }),
                          _vm._v(" Clique aqui "),
                        ]
                      ),
                      _vm._v(" para visualizar o arquivo. "),
                    ]),
                    _c("b-card-text", [
                      _c("input", {
                        ref: "file",
                        staticClass: "form-control",
                        staticStyle: { padding: "0.4rem 0.5rem" },
                        attrs: { type: "file", id: "file" },
                        on: {
                          change: function ($event) {
                            return _vm.handleFileUpload()
                          },
                        },
                      }),
                    ]),
                    _c("small", { staticClass: "d-block mb-1" }, [
                      _vm._v("Limite máximo: 10 MB"),
                    ]),
                    _c("div", { staticClass: "alert alert-primary p-1 mb-0" }, [
                      _vm._v(
                        " Para substituir o arquivo atual, selecione um novo arquivo. "
                      ),
                    ]),
                  ],
                  1
                ),
                _c("ButtonsActionsFooter", {
                  attrs: {
                    routerBack: "notices-list",
                    variant: "success",
                    submited: _vm.submited,
                    text: "Salvar",
                    subtext: "Aguarde...",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }